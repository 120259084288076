import React from "react";
import { Button } from "react-bootstrap";
import { css } from "@emotion/core";

const styles = {
  button: css({
    textTransform: "uppercase",
  }),
};

export default function ButtonNextStep({ children, formikProps }) {
  const { submitForm, submitLabel, isSubmitting, isValid } = formikProps;
  if (!submitForm || (!submitLabel && !children)) {
    return null;
  }
  return (
    <div className="next-step-btn-wrapper">
      <div className="next-step-btn-container">
        <Button
          // css={[styles.button]}
          variant="next-step"
          onClick={submitForm}
          disabled={!isValid || isSubmitting}
          block
        >
          {submitLabel || children}
        </Button>
      </div>
    </div>
  );
}
